@font-face {
  font-family: "Baskerville";
  src: local("Graphik"), url("./Graphik.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Graphik";
  src: local("Graphik"), url("./Graphik.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Garet";
  src: local("Graphik"), url("./Graphik.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Times";
  src: local("Times"), url("./Times.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
